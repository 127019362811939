import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SignupUser from '../views/auth/signup-user';
import Signup from '../views/auth/signup';
import Home from '../views/home';
import Signin from '../views/auth/signin';
import NewTask from '../views/tasks/new';
import About from '../views/about';
import Contact from '../views/contact';
import Partners from '../views/partners';
import Help from '../views/help';
import Terms from '../views/terms';
import Policy from '../views/policy';
import ForgotPassword from '../views/auth/forgot';
import ResetPassword from '../views/auth/reset';
import DeleteAccount from '../views/delete';
import DownloadApp from '../views/download';

export const PublicRoutes = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // handle form submission here
    };

    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/download-app' element={<DownloadApp />} />
            {/* <Route path='/signin' element={<Signin/>} /> */}
            <Route path='/forgot-password' element={<ForgotPassword/>} />
            <Route path='/reset-password' element={<ResetPassword/>} />
            {/* <Route path='/signup-artisan' element={<Signup />} />
            <Route path='/signup-user' element={<SignupUser />} />
            <Route path='/new-task' element={<NewTask />} /> */}
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/partners' element={<Partners />} />
            <Route path='/help-center' element={<Help />} />
            <Route path='/terms-of-service' element={<Terms />} />
            <Route path='/privacy-policy' element={<Policy />} />
            <Route path='/delete-account' element={<DeleteAccount />} />
            <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
    );
};