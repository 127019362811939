import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
    Box,
    Grid,
    GridItem,
  } from "@chakra-ui/react";
  import { IoMdAnalytics, IoIosPerson, IoIosBusiness } from "react-icons/io";
  import { ReactElement } from "react";
  
  import How from '../../assets/how.png';
  import Apple from '../../assets/apple.png';
  import Google from '../../assets/google.png';
  
  
  export default function HowitWorks() {
    return (
      <Box bg={useColorModeValue("red.50", "black")} mt={0} p={10}>
        <Container maxW={"7xl"} mt={20}>
        <Stack spacing={0} mt={20} mb={20} align={'center'}>
          <Heading>Download Our App to Get Started</Heading>
          <Text mb={4}>Find an Artisan today or Start Making More Money with our App</Text>
          <Grid templateColumns={{ sm: '1fr 1fr' }} style={{alignItems: 'center'}} gap={4}>
          <a style={{marginLeft: 15}} href="https://play.google.com/store/apps/developer?id=The+Sapio+Works+LTD" target="blank_">
          <Image width="210px" src={Google} />
          </a>
          <a href="https://apps.apple.com/gb/developer/sapioworks-ltd/id1764118094" target="blank_">
          <Image width="240px" src={Apple} />
          </a>
          </Grid>
        </Stack>
        </Container>
      </Box>
    );
  }
  